import { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '@karnott/colors';
import { AddIcon, ShowLabelIcon } from '@karnott/icons';
import { msDuration } from '@karnott/theme';
import { Container, Icon, Label } from '../shared';

const hoveredStyle = css<{
  backgroundColor: string;
  $color: string;
}>`
  color: ${({ backgroundColor }) => backgroundColor};
  background-color: ${({ $color }) => $color};

  svg * {
    stroke: ${({ backgroundColor }) => backgroundColor};
  }
`;

const ButtonContainer = styled(Container).attrs({ as: 'button' })<{ small: boolean; active: boolean }>`
  cursor: pointer;
  transition: all ${msDuration('short')} ease-in-out;
  ${({ small, active }) =>
    small
      ? css`
          padding: 0;
          align-self: center;
          opacity: ${active ? 1 : 0.6};
        `
      : active
        ? hoveredStyle
        : null}

  :hover {
    ${({ small }) =>
      small
        ? css`
            opacity: 1;
          `
        : hoveredStyle}
  }
`;

type Props = {
  /** If true, the tag is smaller */
  small?: boolean;
  /** Callback when the button is clicked */
  onClick: MouseEventHandler;
  /** If true, the button is active */
  active?: boolean;
  /** Color of the small icon */
  color?: string;
};

/** A button to create a tag */
export function CustomTagButton({ small = false, onClick, active = false, color = colors('black') }: Props) {
  return (
    <ButtonContainer
      $color={colors('grey')}
      backgroundColor={small ? 'transparent' : colors('white')}
      small={small}
      onClick={onClick}
      active={active}
      className="custom-tag-button"
    >
      {small ? (
        <Icon>
          <AddIcon size={14} color={color} />
        </Icon>
      ) : (
        <>
          <Icon>
            <ShowLabelIcon size={12} color={colors('grey')} />
          </Icon>

          <Label>+</Label>
        </>
      )}
    </ButtonContainer>
  );
}
