import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';
import { createAppSlice } from '.';
import {
  addPhytoMixtureProduct,
  createCustomPhytoProduct,
  createPhytoFavoriteProduct,
  createPhytoMixture,
  deletePhytoFavoriteProduct,
  deletePhytoMixtureProduct,
  getIftReports,
  getPhytoBasicProducts,
  getPhytoFavoriteProducts,
  getPhytoMixtures,
  requestIftReport,
  updateCustomPhytoProduct,
  updatePhytoMixture,
  updatePhytoMixtureProduct,
} from '../api/api';
import {
  IFT_STATUS,
  MIXTURE_STATE,
  MixtureState,
  ProductState,
  UsageIssues,
  UsageState,
} from '../constants/phytosConstants';
import { objectMap } from '../utils';
import { ItemWithPermissions } from '../utils/permissions';
import { PartialWithRequired } from '../utils/types';
import { ClusterId } from './clusters';
import { IftBilan } from './ift';
import { alerting, success } from './snacks';

type MixtureId = string;
type ProductId = string;
type ProductUsageId = string;

type ProductUsage = {
  id: ProductUsageId;
  product_id: ProductId;
  short_usage_id: string;
  usage_id: string;
  decision_date: string;
  state: UsageState;
  dose: number;
  dose_unit: string;
  nb_max_application?: number;
};

type ReferenceProduct = ItemWithPermissions & {
  id: ProductId;
  name: string;
  aam_reference: string;
  active_substance: string;
  amm: string;
  commercial_type: string;
  cluster_id: undefined;
  first_authorization_date: string;
  formulations: string;
  functions: string[];
  is_favorite: boolean;
  product_type: string;
  reentry_delays: string[];
  secondary_name: string;
  state: ProductState;
  titular: string;
  usages: ProductUsage[];
  withdrawal_date: string;
};

type CustomProduct = ItemWithPermissions & {
  id: ProductId;
  name: string;
  cluster_id: ClusterId;
  active_substance: string;
  usages: [ProductUsage];
  is_favorite: boolean;
  functions: null;
  reentry_delays: null;
  amm: '';
};

type MixtureProductUsage = {
  is_custom_product: boolean;
  product_id: ProductId;
  product_name: string;
  product_amm: string;
  product_short_risk: string[];
  product_state: ProductState;
  product_usage_id: ProductUsageId;
  product_usage_dose: number;
  product_usage_usage_id: string;
  product_usage_znt: number;
  product_usage_recolt_delay: number;
  product_usage_state: UsageState;
  status: UsageIssues;
  dose: number;
  dose_unit: string;
  function: string;
  product_functions: string[];
  product_reentry_delays: string[];
  unauthorized_with_other_product_ids: ProductId[];
};

type Mixture = ItemWithPermissions & {
  cluster_id: ClusterId;
  id: MixtureId;
  name: string;
  status: MixtureState;
  product_usages: MixtureProductUsage[];
};

type Product = ReferenceProduct | CustomProduct;

type State = {
  products: Record<ClusterId, Product[]>;
  mixtures: Record<ClusterId, Mixture[]>;
  iftReports: Record<ClusterId, IftBilan>;
};

const initialState: State = {
  products: {},
  mixtures: {},
  iftReports: [],
};

function updateProduct(state: State, clusterId: ClusterId, product: Product) {
  state.products[clusterId] ??= [];
  state.products[clusterId] = state.products[clusterId].filter((p) => p.id !== product.id);
  state.products[clusterId].push(product);
}

const phytoSlice = createAppSlice({
  name: 'phyto',
  initialState,
  reducers: (create) => ({
    fetchBasicProducts: create.asyncThunk(async (name: string, { signal }) => {
      const products = (await alerting(() => getPhytoBasicProducts(name, signal))) as Product[];
      return products;
    }),

    // Favorite products
    fetchFavoriteProducts: create.asyncThunk(
      async (clusterId: ClusterId) => {
        if (!clusterId) {
          return;
        }
        const products = (await alerting(() => getPhytoFavoriteProducts(clusterId))) as Product[];
        return products;
      },
      {
        fulfilled: (state, { payload, meta: { arg: clusterId } }) => {
          if (payload) {
            state.products[clusterId] = payload;
          }
        },
      },
    ),
    addFavoriteProduct: create.asyncThunk(
      async ({ productId, clusterId }: { productId: ProductId; clusterId: ClusterId }) => {
        const { product } = (await alerting(() => createPhytoFavoriteProduct(productId, clusterId))) as {
          product: Product;
        };
        success('AdminAddProductModal.success_snack');
        return product;
      },
      {
        fulfilled: (
          state,
          {
            payload,
            meta: {
              arg: { clusterId },
            },
          },
        ) => {
          updateProduct(state, clusterId, payload);
        },
      },
    ),
    removeFavoriteProduct: create.asyncThunk(
      async ({ productId, clusterId }: { productId: ProductId; clusterId: ClusterId }) => {
        await alerting(() => deletePhytoFavoriteProduct(productId, clusterId));
        success('AdminAddProductModal.remove_success_snack');
      },
      {
        fulfilled: (
          state,
          {
            meta: {
              arg: { clusterId, productId },
            },
          },
        ) => {
          state.products[clusterId] ??= [];
          state.products[clusterId] = state.products[clusterId].filter((p) => p.id !== productId);
        },
      },
    ),

    // Custom products
    addCustomProduct: create.asyncThunk(async (product: Partial<CustomProduct>) => {
      const newProduct = (await alerting(() => createCustomPhytoProduct(product))) as CustomProduct;
      return newProduct;
    }),
    updateCustomProduct: create.asyncThunk(
      async (product: PartialWithRequired<CustomProduct, 'id'>) => {
        const updatedProduct = (await alerting(() => updateCustomPhytoProduct(product.id, product))) as CustomProduct;
        success('AdminCustomProductModal.edit_success_snack');
        return updatedProduct;
      },
      {
        fulfilled: (state, { payload }) => {
          updateProduct(state, payload.cluster_id, payload);
        },
      },
    ),

    // Mixtures
    fetchMixtures: create.asyncThunk(
      async (clusterId?: ClusterId) => {
        if (!clusterId) return;
        const mixtures = (await alerting(() => getPhytoMixtures(clusterId))) as Mixture[];
        return mixtures;
      },
      {
        fulfilled: (state, { payload, meta: { arg: clusterId } }) => {
          if (payload && clusterId) {
            state.mixtures[clusterId] = payload;
          }
        },
      },
    ),
    fetchAllMixtures: create.asyncThunk(
      async () => {
        const mixtures = (await alerting(getPhytoMixtures)) as Mixture[];
        const mixturesByClusterId: Record<ClusterId, Mixture[]> = {};
        mixtures.forEach((mixture) => {
          mixturesByClusterId[mixture.cluster_id] ||= [];
          mixturesByClusterId[mixture.cluster_id]?.push(mixture);
        });
        return mixturesByClusterId;
      },
      {
        fulfilled: (state, { payload }) => {
          state.mixtures = payload;
        },
      },
    ),
    addMixture: create.asyncThunk(async (mixture: Partial<Mixture>, { dispatch }) => {
      const newMixture = (await alerting(() => createPhytoMixture(mixture))) as Mixture;
      await dispatch(fetchMixtures(newMixture.cluster_id));
      success('AdminMixture.create_success_snack');
      return newMixture;
    }),
    updateMixture: create.asyncThunk(
      async (
        {
          mixtureId,
          payload,
        }: {
          mixtureId: MixtureId;
          payload: {
            mixtureName?: Mixture['name'];
            clusterId: ClusterId;
            productsToDelete?: ProductUsageId[];
            products?: {
              toUpdate?: boolean;
              toCreate?: boolean;
              product_usage_id: { id: ProductUsageId };
              dose: number | string;
              function: string;
            }[];
          };
        },
        { dispatch },
      ) => {
        const promises: Promise<unknown>[] = [];
        if (payload.mixtureName) {
          promises.push(updatePhytoMixture(mixtureId, { name: payload.mixtureName }));
        }
        if (payload.productsToDelete && Array.isArray(payload.productsToDelete)) {
          payload.productsToDelete.filter(Boolean).forEach((productUsageId) => {
            promises.push(deletePhytoMixtureProduct(mixtureId, productUsageId));
          });
        }
        if (payload.products) {
          payload.products.forEach((product) => {
            if (product.toUpdate) {
              promises.push(
                updatePhytoMixtureProduct(mixtureId, product.product_usage_id.id, {
                  dose: product.dose,
                  function: product.function,
                }),
              );
            } else if (product.toCreate) {
              promises.push(
                addPhytoMixtureProduct(mixtureId, {
                  product_usage_id: product.product_usage_id.id,
                  dose: Number(product.dose),
                }),
              );
            }
          });
        }
        await alerting(() => Promise.all(promises));
        await dispatch(fetchMixtures(payload.clusterId));
        success('AdminMixture.edit_success_snack');
      },
    ),
    archiveMixture: create.asyncThunk(
      async (
        {
          mixtureId,
          clusterId,
          isCurrentlyArchived = false,
        }: {
          mixtureId: MixtureId;
          clusterId: ClusterId;
          isCurrentlyArchived?: boolean;
        },
        { dispatch },
      ) => {
        await alerting(() => updatePhytoMixture(mixtureId, { status: isCurrentlyArchived ? 'active' : 'archived' }));
        await dispatch(fetchMixtures(clusterId));
        success('AdminMixture.edit_success_snack');
      },
    ),

    // IFT
    fetchIftReports: create.asyncThunk(
      async () => {
        const iftReports = (await alerting(getIftReports)) as IftBilan[];
        return iftReports;
      },
      {
        fulfilled: (state, { payload }) => {
          state.iftReports = payload.reduce((p: Record<ClusterId, IftBilan>, c) => {
            if (
              !p[c.cluster_id] ||
              (p[c.cluster_id] && moment(p[c.cluster_id]!.created_at).isBefore(moment(c.created_at)))
            ) {
              p[c.cluster_id] = c;
            }
            return p;
          }, {});
        },
      },
    ),
    addIftReport: create.asyncThunk(
      async ({
        clusterId,
        fromDate,
        toDate,
      }: {
        clusterId: ClusterId;
        fromDate: moment.Moment;
        toDate: moment.Moment;
      }) => {
        const params = new URLSearchParams({ from_date: fromDate.format(), to_date: toDate.format() });
        const report = (await alerting(() => requestIftReport(clusterId, params))) as IftBilan;
        return report;
      },
      {
        fulfilled: (state, { payload }) => {
          state.iftReports[payload.cluster_id] = payload;
        },
      },
    ),
  }),
  selectors: {
    selectMixturesByClusterId: (state) => state.mixtures,
    selectIftReports: (state) => state.iftReports,
  },
});

export const selectActiveMixturesByClusterId = createSelector(
  [phytoSlice.selectors.selectMixturesByClusterId],
  (mixtures) => objectMap(mixtures, (v: Mixture[]) => v.filter((m) => m.status !== MIXTURE_STATE.ARCHIVED)),
);

export const selectIsIftReportInProgress = createSelector(
  [phytoSlice.selectors.selectIftReports],
  (reports) =>
    !!Object.values(reports).find(
      (report) => report.status === IFT_STATUS.IN_PROGRESS || report.status === IFT_STATUS.TODO,
    ),
);

export const {
  fetchBasicProducts,

  addFavoriteProduct,
  fetchFavoriteProducts,
  removeFavoriteProduct,

  addCustomProduct,
  updateCustomProduct,

  fetchMixtures,
  fetchAllMixtures,
  addMixture,
  updateMixture,
  archiveMixture,

  fetchIftReports,
  addIftReport,
} = phytoSlice.actions;

export const { selectMixturesByClusterId, selectIftReports } = phytoSlice.selectors;

export default phytoSlice.reducer;
