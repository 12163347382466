import { Fragment, MouseEvent, useCallback, useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Shade, colors } from '@karnott/colors';
import { KIcon, SaveIcon } from '@karnott/icons';
import {
  DANGER,
  INFO,
  SUCCESS,
  WARNING,
  fontFamily,
  getColorSeverity,
  pixelSpacing,
  size,
  spacing,
} from '@karnott/theme';
import { TooltipMessage } from '@karnott/tooltip';
import { Effects } from './effects';

// Create the keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const Container = styled.div`
  position: relative;
  font-weight: normal;
`;

const Wrapper = styled.button<{
  backgroundColor: string;
  borderColor: string;
  $color: string;
  large?: boolean;
  $loading?: boolean;
  rounded?: boolean;
  thin?: boolean;
  plain?: boolean;
  image?: string;
}>`
  position: relative;
  font-family: ${fontFamily()};
  font-size: ${({ large }) => (large ? size('large') : size())}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ $color }) => $color};
  padding: ${({ thin, large, plain }) =>
    plain ? 0 : thin ? `0 ${spacing('small')}` : large ? 10 : spacing('small')}px;
  border-radius: ${({ rounded, large }) => (rounded ? spacing('large') : large ? 5 : spacing('xSmall'))}px;
  border: ${({ borderColor, disabled, $loading }) => {
    if ($loading) return '1.5px solid rgba(0,0,0,0.4)';
    if (disabled) return '1.5px solid transparent';
    if (borderColor) return `1.5px solid ${borderColor}`;
    return '1.5px solid transparent';
  }};
  transition:
    color 0.1s linear,
    background-color 0.1s linear,
    border-color 0.1s linear;
  &.badge {
    // avoid border color glitch in button group
    transition:
      color 0.1s linear,
      background-color 0.1s linear,
      border-left-color 0.1s linear,
      border-right-color 0.1s linear;
  }
  outline: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  vertical-align: middle;
  gap: ${({ thin }) => (thin ? pixelSpacing('xSmall') : pixelSpacing('small'))};
  ${({ image, large }) =>
    image &&
    css`
      box-sizing: border-box;
      background-image: url(${image});
      background-size: cover;
      background-position: center;
      width: ${large ? 43 : 34}px;
      height: ${large ? 43 : 34}px;
    `};
  ${({ $loading }) =>
    $loading &&
    css`
      color: transparent !important;
      pointer-events: none;
      position: relative;
      overflow: hidden;
      &::before {
        z-index: 100;
        background: rgba(0, 0, 0, 0.4);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      &::after {
        z-index: 101;
        animation: ${rotate} 0.5s linear infinite;
        border-radius: 290486px;
        border-color: transparent transparent #dbdbdb #dbdbdb;
        border-style: solid;
        border-width: 2px;
        content: '';
        display: block;
        height: 1em;
        width: 1em;
        position: absolute !important;
        left: calc(50% - 0.6em);
        opacity: 1 !important;
        box-sizing: content-box;
      }
    `};
`;

const IconContainer = styled.div<{
  $loading?: boolean;
}>`
  display: flex;
  align-items: center;
  ${({ $loading }) =>
    $loading &&
    css`
      svg * {
        stroke: transparent;
      }
    `}
`;

const Badge = styled.div<{
  background: string;
  border: string;
  numberColor: string;
  padding: boolean;
  large?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  left: ${({ large }) => (large ? '-8px' : '-6px')};
  background-color: ${({ background }) => background};
  box-shadow: inset 0 0 0 1.5px ${({ border }) => border};
  transition: all 0.1s linear;
  color: ${({ numberColor }) => numberColor};
  font-size: ${({ large }) => (large ? `${size('xSmall')}px` : '8px')};
  border-radius: 30px;
  font-weight: 500;
  user-select: none;
  height: ${({ large }) => (large ? '15px' : '12px')};
  min-width: ${({ large }) => (large ? '15px' : '12px')};
  padding: 0 ${({ padding }) => padding && '3px'};
  box-sizing: border-box;
`;

const BUTTON_COLOR_MODE = {
  simple: {
    base: 'white',
    font: getColorSeverity(INFO),
    border: getColorSeverity(INFO),
    outlined: 'white',
    outlinedFont: getColorSeverity(INFO),
    outlinedBorder: getColorSeverity(INFO),
    hovered: getColorSeverity(INFO),
    hoveredFont: 'white',
    hoveredBorder: getColorSeverity(INFO),
  },
  primary: {
    base: getColorSeverity(WARNING),
    font: 'white',
    border: getColorSeverity(WARNING),
    outlined: 'white',
    outlinedFont: getColorSeverity(WARNING),
    outlinedBorder: getColorSeverity(WARNING),
    hovered: getColorSeverity(WARNING),
    hoveredFont: 'white',
    hoveredBorder: getColorSeverity(WARNING),
  },
  success: {
    base: getColorSeverity(SUCCESS),
    font: 'white',
    border: getColorSeverity(SUCCESS),
    outlined: 'white',
    outlinedFont: getColorSeverity(SUCCESS),
    outlinedBorder: getColorSeverity(SUCCESS),
    hovered: getColorSeverity(SUCCESS),
    hoveredFont: 'white',
    hoveredBorder: getColorSeverity(SUCCESS),
  },
  danger: {
    base: getColorSeverity(DANGER),
    font: 'white',
    border: getColorSeverity(DANGER),
    outlined: 'white',
    outlinedFont: getColorSeverity(DANGER),
    outlinedBorder: getColorSeverity(DANGER),
    hovered: getColorSeverity(DANGER),
    hoveredFont: 'white',
    hoveredBorder: getColorSeverity(DANGER),
  },
  plain: {
    base: 'transparent',
    font: 'grey',
    border: 'transparent',
    hovered: 'grey',
    hoveredFont: 'black',
    hoveredBorder: 'grey',
  },
} as const;

type ButtonMode = keyof typeof BUTTON_COLOR_MODE;

type Orientation = 'top' | 'right' | 'bottom' | 'left';

type Props = {
  /** Whether the button is disabled */
  disabled?: boolean;
  /** The icon to display on the button */
  Icon?: KIcon;
  /** The image source to display as a background on the button */
  image?: string;
  /** The label of the button */
  title?: string;
  /** The function to call when the button is clicked */
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  /** The URL to link to. If not empty, the button is an HTML link tag. */
  href?: string;
  /** Whether the link should open in a new tab */
  newTab?: boolean;
  /** Whether the button has an outlined style (inverted background color) */
  outlined?: boolean;
  /** Whether the button has an outlined style with neutral colors */
  outlinedNeutral?: boolean;
  /** Whether the button is a success button */
  success?: boolean;
  /** Whether the button is a primary button */
  primary?: boolean;
  /** Whether the button is a danger button */
  danger?: boolean;
  /** Whether the button is a warning button */
  warning?: boolean;
  /** Whether the button is a bleen button */
  bleen?: boolean;
  /** Whether the button has a plain style (only text) */
  plain?: boolean;
  /** Whether the button is large */
  large?: boolean;
  /** Whether the button shows a loader */
  loading?: boolean;
  /** The message to display in a tooltip when hovering the button */
  message?: string;
  /** Whether the button has rounded corners */
  rounded?: boolean;
  /** Whether the button is thin */
  thin?: boolean;
  /** The badge to display on the button. Can be a tick or a number. */
  badge?: boolean | number;
  /** The orientation of the tooltip */
  tooltipOrientation?: Orientation;
  /** Whether the tooltip text should wrap or stay on one line */
  noWrap?: boolean;
};

/** A button to indicate a trigger for an action */
export const Button = ({
  disabled,
  Icon,
  image,
  title,
  onClick,
  href,
  newTab,
  outlined,
  outlinedNeutral,
  success,
  primary,
  danger,
  warning,
  bleen,
  plain,
  large,
  loading,
  message,
  rounded,
  thin,
  badge,
  tooltipOrientation,
  noWrap,
}: Props) => {
  const [hovered, onEnter, onLeave] = Effects.useButtonHover();
  const onClickCB = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      !disabled && onClick && onClick(e);
    },
    [disabled, onClick],
  );
  const [backgroundColor, fontColor, borderColor] = useMemo(() => {
    let bgShade: Shade = hovered ? 600 : 500;
    let fontShade: Shade = 500;
    let mode: ButtonMode = 'simple';
    if (primary) mode = 'primary';
    if (success) mode = 'success';
    if (danger) mode = 'danger';
    if (warning) mode = 'primary'; // for now, warning is orange as primary color
    if (bleen) {
      return hovered
        ? [colors('white'), colors('bleen', 400), colors('white')]
        : outlined
          ? ['transparent', colors('white'), colors('white')]
          : [colors('bleen', 400), colors('white'), colors('white')];
    }
    if (plain) {
      return [
        'transparent',
        colors(!disabled && hovered ? BUTTON_COLOR_MODE.plain.hoveredFont : BUTTON_COLOR_MODE.plain.font, fontShade),
        'transparent',
      ];
    }
    if (hovered && !disabled) {
      return [
        colors(BUTTON_COLOR_MODE[mode].hovered, outlined || outlinedNeutral ? fontShade : bgShade),
        colors(BUTTON_COLOR_MODE[mode].hoveredFont, fontShade),
        colors(BUTTON_COLOR_MODE[mode].hoveredBorder, outlined || outlinedNeutral ? fontShade : bgShade),
      ];
    }
    if (outlined) {
      if (disabled) {
        fontShade = 400;
      }
      return [
        colors(BUTTON_COLOR_MODE[mode].outlined, bgShade),
        colors(BUTTON_COLOR_MODE[mode].outlinedFont, fontShade),
        colors(BUTTON_COLOR_MODE[mode].outlinedBorder, bgShade),
      ];
    } else if (outlinedNeutral) {
      if (disabled) {
        fontShade = 400;
      }
      return [
        colors(BUTTON_COLOR_MODE[mode].outlined, bgShade),
        colors(BUTTON_COLOR_MODE[mode].outlinedFont, fontShade),
        colors('grey', 200),
      ];
    } else {
      if (disabled) {
        bgShade = 400;
      }
      return [
        colors(BUTTON_COLOR_MODE[mode].base, bgShade),
        colors(BUTTON_COLOR_MODE[mode].font, fontShade),
        colors(BUTTON_COLOR_MODE[mode].border, bgShade),
      ];
    }
  }, [hovered, primary, success, danger, warning, bleen, plain, disabled, outlined, outlinedNeutral]);

  const iconSize = useMemo(() => {
    if (thin) return size('regular');
    if (large) return 20;
    return 16;
  }, [large, thin]);

  const element = useMemo(() => {
    if (href) return 'a';
    return 'button';
  }, [href]);

  const DisabledContainer = disabled && message ? Container : Fragment;

  const containerProps = disabled && message ? { onMouseEnter: onEnter, onMouseLeave: onLeave } : {};

  return (
    <DisabledContainer {...containerProps}>
      {disabled && message && hovered && (
        <TooltipMessage
          message={message}
          orientation={tooltipOrientation ? tooltipOrientation : 'right'}
          noWrap={noWrap}
        />
      )}
      <Wrapper
        as={element}
        onMouseEnter={disabled ? undefined : onEnter}
        onMouseLeave={disabled ? undefined : onLeave}
        disabled={disabled}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        $color={fontColor}
        onClick={onClickCB}
        href={disabled ? undefined : href}
        target={href && newTab ? '_blank' : undefined}
        large={large}
        $loading={loading}
        rounded={rounded}
        thin={thin}
        plain={plain}
        image={image}
        className={badge ? 'badge' : undefined}
      >
        {!!Icon && !image && (
          <IconContainer $loading={loading}>
            <Icon color={fontColor} size={iconSize} />
          </IconContainer>
        )}
        {!disabled && message && hovered && (
          <TooltipMessage
            message={message}
            orientation={tooltipOrientation ? tooltipOrientation : 'right'}
            noWrap={noWrap}
          />
        )}
        {!!title && !image && title}
        {badge && !plain && (
          <Badge
            border={borderColor}
            background={fontColor}
            numberColor={backgroundColor}
            padding={badge !== true}
            large={large}
          >
            {badge === true ? (
              <SaveIcon circled backgroundColor="transparent" size={large ? 15 : 12} color={backgroundColor} />
            ) : (
              badge
            )}
          </Badge>
        )}
      </Wrapper>
    </DisabledContainer>
  );
};
