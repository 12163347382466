import { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import 'firebase/functions';
import mixpanel from 'mixpanel-browser';
import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { FarmerIcon, GiftIcon, HelpIcon, NewsIcon } from '@karnott/icons';
import { PopoverMenu as Menu } from '@karnott/popover-menu';
import { DISPLAY_BANNER, USER_CONSTANTS } from '../constants';
import { FAQ_LINKS, KARNOTT_LINKS } from '../constants/links';
import { I18nContext } from '../contexts/I18nProvider';
import { PERMISSIONS_KEYS_BY_ACTION } from '../contexts/permissions';
import { PluginsContext } from '../contexts/plugins';
import { UIContext } from '../contexts/ui';
import fullLogo from '../images/karnott_full_logo.svg';
import logo from '../images/karnott_logo.svg';
import { getTitle } from '../selectors';
import { selectClustersWithRole } from '../slices/clusters';
import { CustomImageLink, CustomLink } from '../ui';
import {
  DemoAccount,
  HeaderWrapper,
  Logo,
  MainNav,
  MainNavItem,
  MainNavLink,
  MainNavSmallScreenItem,
  Quotation,
  RightHeader,
  RightHeaderItem,
  RightHeaderSeparator,
  SmallScreenLogo,
  SmallScreenTitle,
  ToggleContainer,
  UserMenuContent,
  UserPopoverLink,
} from '../ui/Header';
import { ui, zIndex } from '../ui/theme';
import { ANALYTICS, sendAnalyticsLinkClickEvent } from '../utils/AnalyticsConstants';
import { PermissionSwitcher } from './PermissionSwitcher';
import IcMenu from './icons/IcMenu';

const Header = function () {
  const { t } = useContext(I18nContext);
  const { demoAccount } = useContext(UIContext);
  const { hasOverallUserRoleManagementPlugin, hasOverallPhytoPlugin } = useContext(PluginsContext);

  const title = useSelector(getTitle);
  const clustersWithRole = useSelector(selectClustersWithRole);

  const [profileExpanded, setProfileExpanded] = useState(false);

  const canSeeAdminAndDriversScreen = useMemo(() => {
    return clustersWithRole.some((cluster) => cluster.role !== USER_CONSTANTS.ROLE.SEASONAL_DRIVER);
  }, [clustersWithRole]);

  const toggleProfilePopover = useCallback((e) => {
    e.stopPropagation();
    setProfileExpanded((p) => !p);
  }, []);

  const toggleMenu = () => {
    const mainNav = document.getElementById('mainNav');
    if (mainNav) {
      const expanded = mainNav.style.top === '55px';
      if (expanded) {
        mainNav.style.top = '-510px';
      } else {
        mainNav.style.top = '55px';
      }
    }
  };

  return (
    <>
      <HeaderWrapper popUpMenuOpen={profileExpanded}>
        <ToggleContainer onClick={toggleMenu}>
          <IcMenu />
        </ToggleContainer>

        <MainNav id="mainNav" onClick={toggleMenu}>
          <Logo
            to="/"
            exact
            onClick={() => {
              sendAnalyticsLinkClickEvent(window)(ANALYTICS.LABEL.TOP_BAR.DASHBOARD);
            }}
          >
            <img src={fullLogo} alt="logo-karnott" />
          </Logo>

          <MainNavItem
            onClick={() => {
              sendAnalyticsLinkClickEvent(window)(ANALYTICS.LABEL.TOP_BAR.PARCELS);
            }}
          >
            <MainNavLink to="/parcels">{t('Header.parcels')}</MainNavLink>
          </MainNavItem>
          <MainNavItem
            onClick={() => {
              sendAnalyticsLinkClickEvent(window)(ANALYTICS.LABEL.TOP_BAR.EQUIPMENTS);
            }}
          >
            <MainNavLink to="/equipments">{t('Header.equipments')}</MainNavLink>
          </MainNavItem>
          {hasOverallUserRoleManagementPlugin && canSeeAdminAndDriversScreen ? (
            <MainNavItem
              onClick={() => {
                sendAnalyticsLinkClickEvent(window)(ANALYTICS.LABEL.TOP_BAR.DRIVERS);
              }}
            >
              <MainNavLink to="/drivers">{t('Header.drivers')}</MainNavLink>
            </MainNavItem>
          ) : null}
          <MainNavItem
            onClick={() => {
              sendAnalyticsLinkClickEvent(window)(ANALYTICS.LABEL.TOP_BAR.DEVICES);
            }}
          >
            <MainNavLink to="/devices">{t('Header.devices')}</MainNavLink>
          </MainNavItem>
          {hasOverallPhytoPlugin == null ? null : (
            <MainNavItem
              onClick={() => {
                sendAnalyticsLinkClickEvent(window)(ANALYTICS.LABEL.TOP_BAR.PHYTO);
              }}
            >
              <MainNavLink to={'/tasks'}>
                {hasOverallPhytoPlugin ? t('Header.tasks') : t('Header.tasks_upsell')}
              </MainNavLink>
            </MainNavItem>
          )}
          <PermissionSwitcher clusterAuthorization={PERMISSIONS_KEYS_BY_ACTION.read_report}>
            <MainNavItem
              onClick={() => {
                sendAnalyticsLinkClickEvent(window)(ANALYTICS.LABEL.TOP_BAR.REPORTS);
              }}
            >
              <MainNavLink to="/reports">{t('Header.reports')}</MainNavLink>
            </MainNavItem>
          </PermissionSwitcher>
          {canSeeAdminAndDriversScreen ? (
            <MainNavItem
              onClick={() => {
                sendAnalyticsLinkClickEvent(window)(ANALYTICS.LABEL.TOP_BAR.ADMINISTRATION);
              }}
            >
              <MainNavLink to="/admin">{t('Header.admin')}</MainNavLink>
            </MainNavItem>
          ) : null}

          <MainNavSmallScreenItem
            onClick={() => {
              sendAnalyticsLinkClickEvent(window)(ANALYTICS.LABEL.TOP_BAR.PROFILE);
            }}
          >
            <MainNavLink to="/profile">{t('Header.profile')}</MainNavLink>
          </MainNavSmallScreenItem>
          <MainNavSmallScreenItem>
            <MainNavLink to="/logout">{t('Header.logout')}</MainNavLink>
          </MainNavSmallScreenItem>
        </MainNav>

        <SmallScreenTitle>
          <SmallScreenLogo to="/">
            <img src={logo} alt="logo-karnott" />
          </SmallScreenLogo>
          {typeof title === 'string' ? title.replaceAll(' — Karnott', '') : title}
        </SmallScreenTitle>

        <ShadowToggleContainer />

        <RightHeader id="account-menu">
          {new Date() >= new Date('2023-12-01T00:00:00') && new Date() < new Date('2023-12-26T00:00:00') ? (
            <CustomImageLink
              href={KARNOTT_LINKS.AVENT_CALENDAR}
              target="_blank"
              onClick={(e) => {
                sendAnalyticsLinkClickEvent(window)(ANALYTICS.LABEL.TOP_BAR.AVENT_CALENDAR);
                e.stopPropagation();
              }}
            >
              <img src="/tree.gif" alt="Calendrier de l’avent Karnott" />
            </CustomImageLink>
          ) : null}
          <CustomLink
            href={KARNOTT_LINKS.SPONSORSHIP}
            target="_blank"
            onClick={(e) => {
              sendAnalyticsLinkClickEvent(window)(ANALYTICS.LABEL.TOP_BAR.SPONSORSHIP);
              e.stopPropagation();
            }}
          >
            <GiftIcon size={23} backgroundColor={colors('purple')} color={colors('white')} circled />
          </CustomLink>
          <RightHeaderSeparator />
          <RightHeaderItem
            outlined
            onClick={(e) => {
              window.Intercom('trackEvent', 'SHOW_NEWS');
              mixpanel.track('SHOW_NEWS');
              e.stopPropagation();
            }}
          >
            <NewsIcon size={20} color={colors('bleen')} />
            {t('Header.news')}
          </RightHeaderItem>
          <CustomLink
            href={FAQ_LINKS.INDEX}
            target="_blank"
            onClick={(e) => {
              sendAnalyticsLinkClickEvent(window)(ANALYTICS.LABEL.TOP_BAR.HELP);
              e.stopPropagation();
            }}
          >
            <RightHeaderItem>
              <HelpIcon size={12} color="white" />
              <LabelContainer>{t('Header.help')}</LabelContainer>
            </RightHeaderItem>
          </CustomLink>
          <Menu
            bottom
            opened={profileExpanded}
            onRequestClose={() => setProfileExpanded(false)}
            content={
              <UserMenuContent>
                <UserPopoverLink to="/settings">{t('Header.settings')}</UserPopoverLink>
                <UserPopoverLink to="/logout">{t('Header.logout')}</UserPopoverLink>
              </UserMenuContent>
            }
          >
            <RightHeaderItem onClick={toggleProfilePopover}>
              <FarmerIcon size={12} color="white" />
              <LabelContainer>{t('Header.my_account')}</LabelContainer>
            </RightHeaderItem>
          </Menu>
        </RightHeader>
      </HeaderWrapper>
      {DISPLAY_BANNER ? <WarningBanner>{t('Header.warning_banner')}</WarningBanner> : null}
      {demoAccount ? (
        <WarningBanner>
          <DemoAccount>{t('Header.demo_account_banner')}</DemoAccount>
          <Quotation href={KARNOTT_LINKS.QUOTATION} target="_blank" rel="noopener noreferrer">
            {t('Header.quotation_banner')}
          </Quotation>
        </WarningBanner>
      ) : null}
    </>
  );
};

const ShadowToggleContainer = styled(ToggleContainer)`
  @media (orientation: landscape) and (max-width: 1180px), (max-width: 1180px) {
    visibility: hidden;
    width: 54px;
  }
`;

const WarningBanner = styled.div`
  width: 100%;
  height: 41px;
  background-color: ${ui('warning')};
  color: ${colors('grey')};
  z-index: ${zIndex('header', 1)};
  position: fixed;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LabelContainer = styled.div`
  align-items: center;
`;

export default Header;
