import { colors } from '@karnott/colors';
import { AdminIcon, ArchiveIcon, DriverIcon, EyeIcon, ImplementIcon, ShowLabelIcon } from '@karnott/icons';
import {
  deselectAllClusters,
  deselectAllCrops,
  modifyBeaconFilters,
  modifyDriverBeaconFilters,
  modifyDriverFilters,
  modifyEquipmentFilters,
  modifyKarnottFilters,
  modifyParcelsFilters,
  reInitBeaconFilters,
  reInitDriverBeaconFilters,
  reInitDriverFilters,
  reInitEquipmentFilters,
  reInitKarnottFilters,
  reInitParcelsFilters,
  selectAllClusters,
  selectAllCrops,
} from '../actions/filters';
import IcKarnott from '../components/icons/IcKarnott';
import IcKarnottAvailable from '../components/icons/IcKarnottAvailable';
import IcKarnottEmit from '../components/icons/IcKarnottEmit';
import IcKarnottPause from '../components/icons/IcKarnottPause';
import { I18n } from '../i18n';
import { sortAlphaBeta } from '../utils';
import { getBatteryLevelIcon } from '../utils/devices';
import { equipmentTypeLabel, getEquipmentIcon } from '../utils/equipments';
import { getParcelIcon } from '../utils/parcels';
import { undefinedTagItem } from '../utils/tags';
import { getEquipmentTypesList } from './equipmentConstants';
import { PARCELS_CONSTANTS } from './parcelsConstants';

const equipmentTypes = getEquipmentTypesList().map((type) => ({
  label: equipmentTypeLabel(type),
  type,
  icon: getEquipmentIcon(type),
}));

export const filterToggleTypes = {
  IN_USE_EQUIPMENTS: 'IN_USE_EQUIPMENTS',
  AVAILABLE_EQUIPMENTS: 'AVAILABLE_EQUIPMENTS',
  OBSERVATIONS: 'OBSERVATIONS',
  ARCHIVED: 'ARCHIVED',
  ACTIVE_DRIVER: 'ACTIVE_DRIVER',
  AVAILABLE_DRIVER: 'AVAILABLE_DRIVER',
  ADMIN_DRIVER: 'ADMIN_DRIVER',
};

const tagsFilterModel = {
  title: 'ListSelector.all_tags',
  itemsStorePath: 'tags.tagsByClusterId',
  itemsFormatter: (tagsByClusterId) => [
    undefinedTagItem,
    ...Object.values(tagsByClusterId)
      .flat()
      .sort((a, b) => sortAlphaBeta(a.label, b.label)),
  ],
  getItemLabel: (item) => item.label,
  getItemColor: (item) => item.color,
  Icon: ShowLabelIcon,
};

export const initialEquipmentsFilters = {
  toggles: {
    title: 'Filters.equipments_toggles_title',
    items: [
      {
        type: filterToggleTypes.IN_USE_EQUIPMENTS,
        label: 'Filters.in_use_equipments',
        icon: <ImplementIcon color={colors('green')} size={20} />,
      },
      {
        type: filterToggleTypes.AVAILABLE_EQUIPMENTS,
        label: 'Filters.available_equipments',
        icon: <ImplementIcon color={colors('grey', 'light')} size={20} />,
      },
      {
        type: filterToggleTypes.ARCHIVED,
        label: 'Filters.archived_equipments',
        icon: <ArchiveIcon color={colors('grey', 'light')} size={20} />,
      },
      {
        type: filterToggleTypes.OBSERVATIONS,
        label: 'Filters.display_observations',
        icon: <EyeIcon color={colors('orange')} size={20} />,
      },
    ],
  },
  select: {
    ...tagsFilterModel,
    analyticsOnChangeEventPath: 'LABEL.TAGS.FILTER.EQUIPMENT',
  },
  types: {
    title: 'Filters.equipment_types_title',
    items: equipmentTypes,
  },
  sliders: {
    title: 'Filters.equipment_sliders_title',
    items: [{ label: 'width' }],
  },
  modifyFilters: modifyEquipmentFilters,
  reInitFilters: reInitEquipmentFilters,
};

export const UNDEFINED_CROP_ID = 'null';

export const initialParcelsFilters = {
  parcelTypesCheckboxes: {
    title: 'Filters.parcels_toggles_title',
    items: [
      {
        label: 'Filters.field_parcel',
        checkingFunc: (parcel) =>
          parcel.category !== PARCELS_CONSTANTS.CATEGORY.FARMHOUSE &&
          (parcel.status === PARCELS_CONSTANTS.STATUS.ACTIVE ||
            parcel.status === PARCELS_CONSTANTS.STATUS.BEING_RENT_ACTIVE),
        icon: getParcelIcon({ status: PARCELS_CONSTANTS.STATUS.ACTIVE }, { size: 30 }),
      },
      {
        label: 'Filters.being_rent_parcel',
        checkingFunc: (parcel) =>
          parcel.status === PARCELS_CONSTANTS.STATUS.BEING_RENT ||
          parcel.status === PARCELS_CONSTANTS.STATUS.BEING_RENT_ACTIVE,
        icon: getParcelIcon({ status: PARCELS_CONSTANTS.STATUS.BEING_RENT }, { size: 30 }),
      },
      {
        label: 'Filters.rent_over_parcel',
        checkingFunc: (parcel) => parcel.status === PARCELS_CONSTANTS.STATUS.RENT_OVER,
        icon: getParcelIcon({ status: PARCELS_CONSTANTS.STATUS.RENT_OVER }, { size: 30 }),
      },
      {
        label: 'Filters.inactive_parcel',
        checkingFunc: (parcel) => parcel.status === PARCELS_CONSTANTS.STATUS.RENT_INACTIVE,
        icon: getParcelIcon({ status: PARCELS_CONSTANTS.STATUS.RENT_INACTIVE }, { size: 30 }),
      },
      {
        label: 'Filters.farmhouse_parcel',
        checkingFunc: (parcel) => parcel.category === PARCELS_CONSTANTS.CATEGORY.FARMHOUSE,
        icon: getParcelIcon({ category: PARCELS_CONSTANTS.CATEGORY.FARMHOUSE }, { size: 30 }),
      },
    ],
  },
  checkboxes: {
    title: 'Filters.crops_title',
    selectAll: selectAllCrops,
    deselectAll: deselectAllCrops,
  },
  checkboxesClusters: {
    title: 'ListSelector.all_clusters',
    selectAll: selectAllClusters,
    deselectAll: deselectAllClusters,
  },
  sliders: {
    title: 'Filters.parcel_sliders_title',
    items: [{ label: 'area' }],
  },
  modifyFilters: modifyParcelsFilters,
  reInitFilters: reInitParcelsFilters,
};

export const KARNOTT_TOGGLE_TYPE = {
  NOT_IN_USE: 'not_in_use',
  IN_USE: 'in_use',
  AFFECTED: 'affected',
  AVAILABLE: 'available',
  LOW_BATTERY: 'low_battery',
  BATTERY: 'battery',
};

export const initialKarnottFilters = {
  toggles: {
    title: 'Filters.karnott_toggles_title',
    items: [
      {
        type: KARNOTT_TOGGLE_TYPE.NOT_IN_USE,
        label: 'Filters.not_in_use_karnott',
        icon: <IcKarnottPause />,
      },
      {
        type: KARNOTT_TOGGLE_TYPE.IN_USE,
        label: 'Filters.in_use_karnott',
        icon: <IcKarnottEmit />,
        bordered: true,
      },
      {
        type: KARNOTT_TOGGLE_TYPE.AFFECTED,
        label: 'Filters.affected_karnott',
        icon: <IcKarnott />,
      },
      {
        type: KARNOTT_TOGGLE_TYPE.AVAILABLE,
        label: 'Filters.available_karnott',
        icon: <IcKarnottAvailable />,
        bordered: true,
      },
      {
        type: KARNOTT_TOGGLE_TYPE.LOW_BATTERY,
        label: 'Filters.low_battery_karnott',
        icon: getBatteryLevelIcon(20),
      },
      {
        type: KARNOTT_TOGGLE_TYPE.BATTERY,
        label: 'Filters.battery_karnott',
        icon: getBatteryLevelIcon(80),
      },
    ],
  },
  select: {
    ...tagsFilterModel,
    direction: 'top',
    analyticsOnChangeEventPath: 'LABEL.TAGS.FILTER.DEVICE',
  },
  modifyFilters: modifyKarnottFilters,
  reInitFilters: reInitKarnottFilters,
};

export const BEACON_TOGGLE_TYPE = {
  AFFECTED: 'affected',
  AVAILABLE: 'available',
};

export const initialBeaconsFilters = {
  toggles: {
    title: 'Filters.karnott_toggles_title',
    items: [
      {
        type: BEACON_TOGGLE_TYPE.AFFECTED,
        label: 'Filters.affected_beacons',
        icon: <IcKarnott />,
      },
      {
        type: BEACON_TOGGLE_TYPE.AVAILABLE,
        label: 'Filters.available_beacons',
        icon: <IcKarnottAvailable />,
      },
    ],
  },
  select: {
    ...tagsFilterModel,
    analyticsOnChangeEventPath: 'LABEL.TAGS.FILTER.BEACON',
  },
  modifyFilters: modifyBeaconFilters,
  reInitFilters: reInitBeaconFilters,
};

export const initialDriverBeaconsFilters = {
  toggles: {
    title: 'Filters.karnott_toggles_title',
    items: [
      {
        type: BEACON_TOGGLE_TYPE.AFFECTED,
        label: 'Filters.affected_driver_beacons',
        icon: <IcKarnott />,
      },
      {
        type: BEACON_TOGGLE_TYPE.AVAILABLE,
        label: 'Filters.available_driver_beacons',
        icon: <IcKarnottAvailable />,
      },
    ],
  },
  select: {
    ...tagsFilterModel,
    analyticsOnChangeEventPath: 'LABEL.TAGS.FILTER.DRIVER_BEACON',
  },
  modifyFilters: modifyDriverBeaconFilters,
  reInitFilters: reInitDriverBeaconFilters,
};

export const initialDriversFilters = {
  toggles: {
    title: 'Filters.drivers_toggles_title',
    items: [
      {
        type: filterToggleTypes.ACTIVE_DRIVER,
        label: 'Filters.active_driver',
        icon: <DriverIcon color={colors('green')} size={20} />,
      },
      {
        type: filterToggleTypes.AVAILABLE_DRIVER,
        label: 'Filters.available_driver',
        icon: <DriverIcon color={colors('grey', 'light')} size={20} />,
      },
      {
        type: filterToggleTypes.ADMIN_DRIVER,
        label: 'Filters.admin_driver',
        icon: <AdminIcon color={colors('grey', 'light')} size={20} />,
      },
    ],
  },
  select: {
    ...tagsFilterModel,
    analyticsOnChangeEventPath: 'LABEL.TAGS.FILTER.DRIVER',
  },
  modifyFilters: modifyDriverFilters,
  reInitFilters: reInitDriverFilters,
};

export const initialEquipmentSessionsFilters = {
  clusters: {
    items: [],
  },
  status: {
    items: [
      { id: 'computed', value: 'computed', label: 'EquipmentSession.computed_status', checked: true },
      { id: 'processed', value: 'processed', label: 'EquipmentSession.processed_status', checked: true },
      { id: 'billed', value: 'billed', label: 'EquipmentSession.billed_status', checked: true },
      { id: 'archived', value: 'archived', label: 'EquipmentSession.archived_status', checked: false },
      { id: 'not_relevant', value: 'not_relevant', label: 'EquipmentSession.not_relevant_status', checked: false },
    ],
  },
};

export const UNDEFINED_TAG_ID = 'null';

export const MIXTURE_FILTER = { VISIBLE: 'visible', ARCHIVED: 'archived' };

export const adminMixtureFilters = [
  { id: MIXTURE_FILTER.VISIBLE, label: I18n.t('Admin.filter_mixture_visible') },
  { id: MIXTURE_FILTER.ARCHIVED, label: I18n.t('Admin.filter_mixture_archived') },
];
