import pickBy from 'lodash-es/pickBy';
import { replace } from 'redux-first-history';
import { createSelector } from '@reduxjs/toolkit';
import { createAppSlice } from '.';
import { userLinkedWithSuccess } from '../actions/user';
import {
  createCluster as createApiCluster,
  getClusters,
  getClustersPartners,
  linkCluster,
  linkUserToClusterByMail,
  unlinkCluster as unlinkApiCluster,
  updateCluster as updateApiCluster,
} from '../api/api';
import { USER_CONSTANTS, UserRole } from '../constants';
import { CLUSTER_CONSTANTS, CLUSTER_TYPE, ClusterActivity, ClusterType } from '../constants/clusterConstants';
import { PERMISSIONS_KEYS_BY_ACTION } from '../constants/permissionsConstants';
import { ItemWithPermissions } from '../utils/permissions';
import { PartialWithRequired } from '../utils/types';
import { alerting, success } from './snacks';

type PluginId = string;
type Plugin = {
  activated: boolean;
  expired_at: string;
  id: PluginId;
  option: string;
};

export type ClusterId = number;
export type Cluster = ItemWithPermissions & {
  id: ClusterId;
  name: string;
  address?: string;
  cluster_type: ClusterType;
  color?: string;
  role?: UserRole;
  activities?: ClusterActivity[];
  count_admin: number;
  count_parcels: number;
  size_parcels: number;
  email?: string;
  from_application: string;
  is_demo: boolean;
  linked_partner: string;
  parent_cluster_ids?: ClusterId[];
  phone: string;
  plugins: Plugin[];
  siren: string;
};

type ClusterPartner = {
  cluster_id: ClusterId;
  linked_from: string;
  partner: string;
};
type ClusterPartners = {
  cluster_id: ClusterId;
  linked_from: string[];
  partner: string[];
};

type State = {
  isFetching: boolean;
  clusterById: Record<ClusterId, Cluster>;
  clustersIds: ClusterId[];
  clusterPartners: Record<ClusterId, ClusterPartners>;
  clusterPartnersFetched: boolean;
};

const initialState: State = {
  isFetching: false,
  clusterById: {},
  clustersIds: [],
  clusterPartners: {},
  clusterPartnersFetched: false,
};

function addClusterToState(state: State, cluster: Cluster) {
  state.clustersIds = state.clustersIds.filter((id) => cluster.id !== id);
  state.clustersIds.push(cluster.id);
  state.clusterById[cluster.id] = { ...state.clusterById[cluster.id], ...cluster };
}

const clustersSlice = createAppSlice({
  name: 'clusters',
  initialState,
  reducers: (create) => ({
    fetchClusters: create.asyncThunk(
      async (_, { dispatch }) => {
        const clusters = (await alerting(getClusters)) as Cluster[];
        if (clusters.length === 0) {
          dispatch(replace('/unlinked'));
        }
        return clusters;
      },
      {
        pending: (state) => {
          state.isFetching = true;
        },
        fulfilled: (state, { payload }) => {
          for (const cluster of payload) {
            addClusterToState(state, cluster);
          }
        },
        settled: (state) => {
          state.isFetching = false;
        },
      },
    ),
    createCluster: create.asyncThunk(
      async (
        payload: {
          cluster: Partial<Cluster>;
          clusterIds: ClusterId[];
          email?: string;
          redirectToAdmin?: boolean;
        },
        { dispatch },
      ) => {
        const createdCluster = (await alerting(() => createApiCluster(payload.cluster))) as Cluster;

        for (const clusterId of payload.clusterIds) {
          await alerting(() => linkCluster(clusterId, createdCluster.id));
        }

        if (payload.email) {
          const link = await alerting(() =>
            linkUserToClusterByMail({ email: payload.email, clusterID: createdCluster.id }),
          );
          success('Clusters.user_linked_successfully', { params: { email: payload.email } });
          dispatch(userLinkedWithSuccess(link, createdCluster.id));
        }

        payload.redirectToAdmin && dispatch(replace('/admin'));
        return createdCluster;
      },
      {
        fulfilled: (state, { payload }) => {
          addClusterToState(state, payload);
        },
      },
    ),
    updateCluster: create.asyncThunk(
      async (payload: { cluster: PartialWithRequired<Cluster, 'id'>; withRedirection?: boolean }, { dispatch }) => {
        const updatedCluster = (await alerting(() => updateApiCluster(payload.cluster))) as Cluster;
        payload.withRedirection !== false && dispatch(replace('/admin'));
        return updatedCluster;
      },
      {
        fulfilled: (state, { payload }) => {
          addClusterToState(state, payload);
        },
      },
    ),
    unlinkCluster: create.asyncThunk(
      async (payload: { parentId: ClusterId; childId: ClusterId }) => {
        await alerting(() => unlinkApiCluster(payload.parentId, payload.childId));
        success('Admin.unlink_cluster');
        return payload.childId;
      },
      {
        fulfilled: (state, { payload }) => {
          state.clustersIds = state.clustersIds.filter((id) => id !== payload);
          delete state.clusterById[payload];
        },
      },
    ),
    fetchClusterPartners: create.asyncThunk(
      async () => {
        const clusterPartners = ((await alerting(getClustersPartners)) || []) as ClusterPartner[];
        return clusterPartners;
      },
      {
        fulfilled: (state, { payload }) => {
          state.clusterPartners = {};
          for (const clusterPartner of payload) {
            state.clusterPartners[clusterPartner.cluster_id] ??= {
              cluster_id: clusterPartner.cluster_id,
              linked_from: [],
              partner: [],
            };
            state.clusterPartners[clusterPartner.cluster_id]!.linked_from.push(clusterPartner.linked_from);
            state.clusterPartners[clusterPartner.cluster_id]!.partner.push(clusterPartner.partner);
          }
          state.clusterPartnersFetched = true;
        },
      },
    ),
  }),
  selectors: {
    selectClusters: (state) => state.clusterById,
    selectClusterIds: (state) => state.clustersIds,
  },
});

export const selectClusterArray = createSelector(
  [clustersSlice.selectors.selectClusters, clustersSlice.selectors.selectClusterIds],
  (clusters, clusterIds) => clusterIds.map((id) => clusters[id]).filter(Boolean),
);

const selectAdminClusters = createSelector([selectClusterArray], (clusters) =>
  clusters.filter((c) => c.role === USER_CONSTANTS.ROLE.ADMIN),
);

export const selectClustersWithRole = createSelector([selectClusterArray], (clusters) =>
  clusters.filter((c) => c.role && Object.values(USER_CONSTANTS.ROLE).includes(c.role)),
);

export const selectParentClusters = createSelector([selectClusterArray], (clusters) => {
  // If there is only one cluster and it's a farm, we must consider it as a cluster parent
  if (clusters.length === 1 && clusters[0]!.cluster_type === CLUSTER_TYPE.FARM) {
    return clusters;
  }
  const clusterTypes = Object.values(CLUSTER_TYPE).filter((t) => t !== CLUSTER_TYPE.FARM);
  return clusters.filter((cluster) => clusterTypes.includes(cluster.cluster_type));
});

const selectParentClustersWithoutLinkedPartner = createSelector([selectParentClusters], (clusters) =>
  clusters.filter((cluster) => !cluster.linked_partner),
);

export const selectAdminParentClustersWithoutLinkedPartner = createSelector([selectParentClusters], (clusters) =>
  clusters.filter((cluster) => cluster.role === USER_CONSTANTS.ROLE.ADMIN && !cluster.linked_partner),
);

export const selectNumberOfDemoClusters = createSelector(
  [selectClusterArray],
  (clusters) => clusters.filter((c) => c.is_demo).length,
);

export const selectIsViticultureContext = createSelector([selectAdminClusters], (clusters) =>
  clusters.some((c) => c?.activities?.length === 1 && c.activities[0] === CLUSTER_CONSTANTS.ACTIVITIES.viticulture),
);

export const selectIsDeneigementContext = createSelector([selectAdminClusters], (clusters) =>
  clusters.some((c) => c?.activities?.length === 1 && c.activities[0] === CLUSTER_CONSTANTS.ACTIVITIES.deneigement),
);

export const selectParentClustersWhichCanCreateClusterMember = createSelector([selectParentClusters], (clusters) => {
  return clusters.filter((c) => c.authorizations && c.authorizations.can_create_cluster_member);
});

export const selectClustersWhichCanCreateUser = createSelector([selectParentClustersWithoutLinkedPartner], (clusters) =>
  clusters.filter(
    (c) => Array.isArray(c.authorizations?.can_create_user) && c.authorizations.can_create_user.length > 0,
  ),
);

export const selectClustersByIdWhichCanCreateParcel = createSelector(
  [clustersSlice.selectors.selectClusters],
  (clusters) => {
    return pickBy(clusters, (c) => c.authorizations[PERMISSIONS_KEYS_BY_ACTION.create_parcel]);
  },
);

export const { fetchClusters, createCluster, updateCluster, unlinkCluster, fetchClusterPartners } =
  clustersSlice.actions;

export default clustersSlice.reducer;
