import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { Button } from '@karnott/buttons';
import { colors } from '@karnott/colors';
import { KIcon, MarkerFillIcon } from '@karnott/icons';
import { Tag } from '@karnott/tags';
import { fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';

const TrackTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px;
  font-family: ${fontFamily()};
`;

const TrackTooltipRowLabel = styled.div`
  flex-direction: row;
`;

const TrackTooltipRow = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: center;
  margin-bottom: 5px;
  gap: 5px;
  font-size: 13px;
`;

const TrackTooltipRowHeader = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: center;
  margin-bottom: ${pixelSpacing('small')};
`;

const TrackTooltipRowHeaderLogo = styled.div`
  display: flex;
`;

const TrackTooltipRowHeaderLabel = styled.div`
  margin-left: ${pixelSpacing('small')};
`;
const TrackTooltipRowHeaderLabelName = styled.div`
  font-weight: 700;
  font-size: ${pixelSize('large')};
`;
const TrackTooltipRowAction = styled.div`
  display: flex;
  gap: 5px;
  height: 24px;
  flex-direction: row;
  justify-content: right;
  margin-top: 10px;
`;

type Props = {
  /** Icon to display */
  Icon: KIcon;
  /** Name of the equipment */
  equipmentName?: string;
  /** Type of worksite */
  worksiteType: string;
  /** Start date of the track */
  fromDate: string;
  /** End date of the track */
  toDate?: string;
  /** Action to perform when clicking on the button */
  action?: MouseEventHandler<HTMLButtonElement>;
  /** I18n labels */
  labels: {
    active: string;
    start: string;
    end: string;
    see: string;
  };
};

export function TrackPopup({ Icon, equipmentName, worksiteType, fromDate, toDate, action, labels }: Props) {
  return (
    <TrackTooltipWrapper>
      <TrackTooltipRowHeader>
        <TrackTooltipRowHeaderLogo>
          <Icon circled backgroundColor={colors('black', 'dark')} color={colors('white')} size={40} />
        </TrackTooltipRowHeaderLogo>
        <TrackTooltipRowHeaderLabel>
          <TrackTooltipRowHeaderLabelName>{equipmentName || worksiteType}</TrackTooltipRowHeaderLabelName>
          {!toDate && <Tag active label={labels.active.toLocaleUpperCase()} />}
        </TrackTooltipRowHeaderLabel>
      </TrackTooltipRowHeader>
      <TrackTooltipRow>
        <MarkerFillIcon size={12} color={colors('orange')} />
        <TrackTooltipRowLabel>{`${labels.start} : ${fromDate}`}</TrackTooltipRowLabel>
      </TrackTooltipRow>
      <TrackTooltipRow>
        <MarkerFillIcon size={12} color={colors('green')} />
        <TrackTooltipRowLabel>{`${labels.end} : ${toDate || '-'}`}</TrackTooltipRowLabel>
      </TrackTooltipRow>
      {action && (
        <TrackTooltipRowAction>
          <Button primary thin title={labels.see} onClick={action} rounded />
        </TrackTooltipRowAction>
      )}
    </TrackTooltipWrapper>
  );
}
