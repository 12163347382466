import styled from 'styled-components';
import { pixelSize, pixelSpacing } from '@karnott/theme';

export const Container = styled.div<{
  $color: string;
  backgroundColor: string;
  small: boolean;
}>`
  color: ${({ $color }) => $color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ small, $color }) => (small ? 'none' : `1px solid ${$color}`)};
  border-radius: 1000px;
  padding: ${({ small }) => (small ? `2px ${pixelSpacing('xSmall')}` : `${pixelSpacing('xSmall')} 6px`)};
  font-size: ${({ small }) => (small ? pixelSize('small') : pixelSize('regular'))};
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;

  &:hover .tag-delete-button {
    display: block;
  }
`;

export const Icon = styled.div`
  flex-shrink: 0;
`;

export const Label = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
