import { v4 } from 'uuid';
import { createAppSlice } from '.';
import { errorHasOccurred } from '../actions';
import { SNACK_CONSTANTS } from '../constants/snackConstants';
import { I18n, LocaleKey } from '../i18n';
import { Values } from '../utils/types';

type SnackId = string;

export type SnackType = Values<typeof SNACK_CONSTANTS.TYPE>;
export type SnackAction = Values<typeof SNACK_CONSTANTS.ACTION>;
export type Snack = {
  message: string;
  duration?: number;
  type?: SnackType;
  action?: SnackAction;
};

type SnackWithId = {
  id: SnackId;
} & Snack;

type State = {
  byId: Record<SnackId, SnackWithId>;
  queue: SnackId[];
};

const initialState: State = {
  byId: {},
  queue: [],
};

function addIdToSnack(snack: Snack): { payload: SnackWithId } {
  return { payload: { id: v4(), ...snack } };
}

export const snacksSlice = createAppSlice({
  name: 'snacks',
  initialState,
  reducers: (create) => ({
    snackAdded: create.preparedReducer(addIdToSnack, (state, { payload }) => {
      state.byId[payload.id] = payload;
      state.queue.push(payload.id);
    }),
    criticalSnackAdded: create.preparedReducer(addIdToSnack, (state, { payload }) => {
      state.byId[payload.id] = payload;
      state.queue.unshift(payload.id);
    }),
    snackDismissed: create.reducer<SnackId>((state, { payload }) => {
      state.queue = state.queue.filter((id) => id !== payload);
    }),
    snacksCleared: create.reducer((state) => {
      state.queue = [];
    }),
    signalSuccess: create.asyncThunk(
      (
        payload: {
          messageKey: LocaleKey;
          options?: { duration?: number; action?: SnackAction; params?: Parameters<typeof I18n.t>[1] };
        },
        { dispatch },
      ) => {
        const options = payload.options || {};
        dispatch(
          snackAdded({
            type: 'success',
            duration: options.duration || 3000,
            message: I18n.t(payload.messageKey, options.params),
            action: options.action || 'OK',
          }),
        );
      },
    ),
    signalError: create.asyncThunk((error: unknown, { dispatch }) => {
      return errorHasOccurred(error, dispatch);
    }),
  }),
});

export const { snackAdded, criticalSnackAdded, snacksCleared, snackDismissed, signalSuccess, signalError } =
  snacksSlice.actions;

export async function alerting(call: () => Promise<unknown>): Promise<unknown> {
  try {
    return await call();
  } catch (e) {
    return signalError(e);
  }
}

export function success(
  messageKey: LocaleKey,
  options: { duration?: number; action?: SnackAction; params?: Parameters<typeof I18n.t>[1] } = {},
) {
  signalSuccess({ messageKey, options });
}

export default snacksSlice.reducer;
