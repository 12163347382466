import mixpanel from 'mixpanel-browser';
import { karnottTrackingEvent } from '../api/api';
import Sentry from './Sentry';

export const ANALYTICS = {
  CATEGORY: {
    BUTTON: 'BUTTON',
    LINK: 'LINK',
    TRANSLATION: 'TRANSLATION',
    TOOLTIP: 'TOOLTIP',
  },
  ACTION: {
    CLICK: 'CLICK',
    TRANSLATE: 'TRANSLATE',
  },
  LABEL: {
    APP: {
      CONTINUE_TO_WEBSITE: 'APP - CONTINUE TO WEBSITE',
      GO_TO_APPLICATION_STORE: 'APP - GO TO APPLICATION STORE',
      COLORIZE_PARCELS: 'APP - COLORATION DES PARCELLES',
      COLORIZE_TRACKS: 'APP - COLORATION DES TRACES',
    },
    TOP_BAR: {
      DASHBOARD: 'TOPBAR - DASHBOARD',
      PARCELS: 'TOPBAR - PARCELLES',
      EQUIPMENTS: 'TOPBAR - MATERIELS',
      DEVICES: 'TOPBAR - KARNOTT',
      PHYTO: 'TOPBAR - PHYTO',
      REPORTS: 'TOPBAR - RAPPORT',
      ADMINISTRATION: 'TOPBAR - ADMINISTRATION',
      HELP: 'TOPBAR - AIDE',
      NOTIF: 'TOPBAR - NOTIFICATIONS',
      PROFILE: 'TOPBAR - PROFILE',
      DRIVERS: 'TOPBAR - CHAUFFEURS',
      SPONSORSHIP: 'TOPBAR - PARRAINAGE',

      AVENT_CALENDAR: 'TOPBAR - CALENDRIER AVENT',
    },
    DATE_PICKER: {
      SHORTCUTS: (context = '', value = '') => `DATE PICKER - ${value} - ${context.toLocaleUpperCase()}`,
    },
    PARCELS: {
      ADD_PARCEL: 'PARCELLES - ACCES A AJOUT DE PARCELLES',
      SAVE_PARCEL_ADD_CLUSTER: 'PARCELLES - AJOUT PARCELLE - AJOUT ADHERENT - ENREGISTRER',
      SAVE_ANON_PARCEL: 'PARCELLES - AJOUT PARCELLE - ANONYMES',
      SAVE_DRAWN_PARCEL: 'PARCELLES - AJOUT PARCELLE - DESSIN',
      SAVE_PARCEL: 'PARCELLES - AJOUT PARCELLE - ENREGISTRER',
      SAVE_EDIT_PARCEL: 'PARCELLES - EDITION PARCELLE - ENREGISTRER',
      IMPORT_PARCEL: 'PARCELLES - IMPORT PARCELLE',
      VALIDATE_IMPORT_PARCEL: 'PARCELLES - IMPORT PARCELLE - IMPORTER',
    },
    EQUIPMENTS: {
      ADD_EQUIPMENT: 'MATERIELS - AJOUT MATERIEL',
      SAVE_EQUIPMENT: 'MATERIELS - AJOUT MATERIAL - ENREGISTRER',
    },
    DEVICES: {
      ATTACH_BEACON: 'BEACON - ASSOCIER MATERIEL',
      ATTACH_DRIVER_BEACON: 'BEACON - ASSOCIER CHAUFFEUR',
      DETACH_DRIVER_BEACON: 'BEACON - DEASSOCIER CHAUFFEUR',
      OPEN_ATTACH_DRIVER_BEACON: 'BEACON - OUVRIR ASSOCIER CHAUFFEUR',
      ATTACH_DEVICE: 'KARNOTT - ASSOCIER MATERIEL',
      DETACH_DEVICE: 'KARNOTT - DESASSOCIER KARNOTT',
    },
    REPORTS: {
      OBS: 'RAPPORTS - OBSERVATIONS',
      PHYTO: 'RAPPORTS - PHYTO',
      PHYTO_IFT: 'RAPPORTS - PHYTO - IFT',
      PHYTO_IFT_GENERATE: 'RAPPORTS - PHYTO - IFT - LANCEMENT CALCUL',
      PHYTO_IFT_DOWNLOAD: 'RAPPORTS - PHYTO - IFT - TELECHARGEMENT',
      EQUIPMENTS_MAP: 'RAPPORTS - CARTE MATERIELS',
      PARCELS_MAP: 'RAPPORTS - CARTE PARCELLES',
      EQUIPMENTS_TABLE: 'RAPPORTS - TABLEAU DONNEES MATERIELS',
      PARCELS_TABLE: 'RAPPORTS - TABLEAU DONNEES PARCELLES',
      CSV_EXPORT: 'RAPPORTS - CSV EXPORT',
      EXCEL_EXPORT: 'RAPPORTS - EXCEL EXPORT',
      OBS_CSV_EXPORT: 'RAPPORTS - OBS CSV EXPORT',
      OBS_EXCEL_EXPORT: 'RAPPORTS - OBS EXCEL EXPORT',
      CSV_DETAILED_EXPORT: 'RAPPORTS - CSV EXPORT DETAILLE',
      EXCEL_DETAILED_EXPORT: 'RAPPORTS - EXCEL EXPORT DETAILLE',
      PARCELS_CSV_EXPORT: 'RAPPORTS - PARCELLES CSV EXPORT',
      PARCELS_EXCEL_EXPORT: 'RAPPORTS - PARCELLS EXCEL EXPORT',
      PARCELS_CSV_DETAILED_EXPORT: 'RAPPORTS - PARCELLES CSV EXPORT DETAILLE',
      PARCELS_EXCEL_DETAILED_EXPORT: 'RAPPORTS - PARCELLES EXCEL EXPORT DETAILLE',
      EQUIPMENTS_CSV_EXPORT: 'RAPPORTS - MATERIELS CSV EXPORT',
      EQUIPMENTS_EXCEL_EXPORT: 'RAPPORTS - MATERIELS EXCEL EXPORT',
      EQUIPMENTS_CSV_DETAILED_EXPORT: 'RAPPORTS - MATERIELS CSV EXPORT DETAILLE',
      EQUIPMENTS_EXCEL_DETAILED_EXPORT: 'RAPPORTS - MATERIELS EXCEL EXPORT DETAILLE',
      WORKSITES_CSV_EXPORT: 'RAPPORTS - PAR CHANTIERS CSV EXPORT',
      WORKSITES_EXCEL_EXPORT: 'RAPPORTS - PAR CHANTIERS EXCEL EXPORT',
      WORKSITES_BY_CLUSTER_CSV_EXPORT: 'RAPPORTS - PAR EXPLOITATIONS CSV EXPORT',
      WORKSITES_BY_CLUSTER_EXCEL_EXPORT: 'RAPPORTS - PAR EXPLOITATIONS EXCEL EXPORT',
      DELETE_OBS: 'RAPPORTS - SUPPRIMER OBSERVATION',
      WORKSITES_MAP: 'RAPPORTS - CARTE CHANTIER',
      WORKSITES_TABLE: 'RAPPORTS - DONNEES CHANTIER',
      EXPAND_LEFT_MENU: 'RAPPORTS - ETENDRE MENU',
      COLLAPSE_LEFT_MENU: 'RAPPORTS - RETRACTER MENU',
    },
    ADMINISTRATION: {
      ADD_CLUSTER: 'ADMINISTRATION - AJOUT EXPLOITATION',
      ADD_USER: 'ADMINISTRATION - AJOUT UTILISATEUR',
      UNLINK_USER: 'ADMINISTRATION - RETIRER UTILISATEUR',
      UNLINK_CLUSTER: 'ADMINISTRATION - RETIRER EXPLOITATION',
      CLUSTER_EDIT: 'ADMINISTRATION - EDITION EXPLOITATION',
      USER_EDIT: 'ADMINISTRATION - EDITION UTILISATEUR',
      CSV_EXPORT: 'ADMINISTRATION - EXPORT CSV',
      EXCEL_EXPORT: 'ADMINISTRATION - EXPORT EXCEL',
      CREATE_CROP: 'ADMINISTRATION - AJOUT CULTURE',
      UPDATE_CROP: 'ADMINISTRATION - MODIFIER CULTURE',
      DELETE_CROP: 'ADMINISTRATION - SUPPRIMER CULTURE',
      CREATE_OBSERVATION_CATEGORY: 'ADMINISTRATION - AJOUT OBSERVATION CAT',
      UPDATE_OBSERVATION_CATEGORY: 'ADMINISTRATION - MODIFIER OBSERVATION CAT',
      DELETE_OBSERVATION_CATEGORY: 'ADMINISTRATION - SUPPRIMER OBSERVATION CAT',
      CREATE_TAG: 'ADMINISTRATION - AJOUT ETIQUETTE',
      UPDATE_TAG: 'ADMINISTRATION - MODIFIER ETIQUETTE',
      DELETE_TAG: 'ADMINISTRATION - SUPPRIMER ETIQUETTE',
    },
    PARCEL: {
      ADD_OBS: 'PARCELLE - AJOUTER UNE OBSERVATION', // missing
      CLICK_ON_CARD: 'PARCELLE - CLIC SUR CARTE',
      DELETE_OBS: 'PARCELLE - SUPRIMER UNE OBSERVATION', // missing
      GO_TO_PARCEL: 'PARCELLE - SE RENDRE A LA PARCELLE',
      MODIFY: 'PARCELLE - MODIFIER', // missing
      DELETE: 'PARCELLE - SUPPRIMER',
      CSV_EXPORT: 'PARCELLE - DETAIL - CSV EXPORT',
      EXCEL_EXPORT: 'PARCELLE - DETAIL - EXCEL EXPORT',
      CSV_BILAN_EXPORT: 'PARCELLE - BILAN - CSV EXPORT',
      EXCEL_BILAN_EXPORT: 'PARCELLE - BILAN - EXCEL EXPORT',
      RESULTS: 'PARCELLE - ONGLET BILAN',
      SESSIONS: 'PARCELLE - ONGLET SESSIONS',
      OBSERVATIONS: 'PARCELLE - ONGLET OBSERVATIONS',
      SHOW_TRACKS: 'PARCELLE - AFFICHER TRAVAIL',
      LIST: 'PARCELLE - LISTE DES INTERVENTIONS', // missing
      TIMELINE: 'PARCELLE - TIMELINE', // missing
      CHART: 'PARCELLE - DIAGRAMME', // missing
      GO_TO_SESSION: 'PARCELLE - CONSULTER INTERVENTION',
      ADD_SINGLE_ANON: 'PARCELLE - AJOUT SIMPLE PARCELLE ANONYNME',
      HISTORY: 'PARCELLE - HISTORY',
      RENT_PARCEL: 'PARCELLE - AFFICHER MODAL AJOUT LOCATION',
      SAVE_RENT_PARCEL: 'PARCELLE - SAUVEGARDER LOCATION',
      CROPS: 'PARCELLE - CULTURES',
      LINK_CROP: 'PARCELLE - ASSOCIER CULTURE',
      UNLINK_CROP: 'PARCELLE - DESASSOCIER CULTURE',
      PHYTO: 'PARCELLE - REGISTRE PHYTO',
    },
    EQUIPMENT: {
      ADD_OBS: 'MATERIEL - AJOUTER UNE OBSERVATION',
      CHANGE_DIMENSION: 'MATERIEL - CHANGER DIMENSION PAR DEFAUT',
      CLICK_ON_CARD: 'MATERIEL - CLIC SUR CARTE',
      DELETE_OBS: 'MATERIEL - SUPRIMER UNE OBSERVATION',
      RENAME: 'MATERIEL - RENOMMER',
      CHANGE_COLOR: 'MATERIEL - CHANGEMENT COULEUR',
      GO_TO_EQUIPMENT: 'MATERIEL - SE RENDRE AU MATERIEL',
      DELETE: 'MATERIEL - DELETE',
      ARCHIVE: 'MATERIEL - ARCHIVE',
      UNARCHIVE: 'MATERIEL - DESARCHIVE',
      ATTACH_DEVICE: 'MATERIEL - MODAL ASSOCIER KARNOTT',
      VALIDATE_ATTACH_DEVICE: 'MATERIEL - ASSOCIER KARNOTT - VALIDER',
      DETACH_DEVICE: 'MATERIEL - DESASSOCIER KARNOTT',
      CSV_EXPORT: 'MATERIEL - DETAIL - CSV EXPORT',
      EXCEL_EXPORT: 'MATERIEL - DETAIL - EXCEL EXPORT',
      CSV_BILAN_EXPORT: 'MATERIEL - BILAN - CSV EXPORT',
      EXCEL_BILAN_EXPORT: 'MATERIEL - BILAN - EXCEL EXPORT',
      RESULTS: 'MATERIEL - ONGLET BILAN', // missing
      SESSIONS: 'MATERIEL - ONGLET SESSIONS',
      OBSERVATIONS: 'MATERIEL - ONGLET OBSERVATIONS',
      LIST: 'MATERIEL - LISTE DES INTERVENTIONS', // missing
      TIMELINE: 'MATERIEL - TIMELINE', // missing
      GO_TO_SESSION: 'MATERIEL - CONSULTER INTERVENTION', // missing
      SHOW_WORK: 'MATERIEL - AFFICHER TRAVAIL EN PARCELLE', // missing
      SHOW_WORK_WITH_TRANSPORT: 'MATERIEL - AFFICHER TRAVAIL AVEC TRAJETS', // missing
    },
    DRIVER: {
      CLICK_ON_CARD: 'CHAUFFEUR - CLIC SUR CARTE',
      CREATE_WITHOUT_BEACON: 'CHAUFFEUR - CREATION SANS BEACON',
      CREATE_WITH_BEACON: 'CHAUFFEUR - CREATION AVEC BEACON',
      EXTEND_SESSION: 'CHAUFFEUR - CLIC SUR SESSION',
      LINK_BEACON: 'CHAUFFEUR - ASSOCIATION BEACON',
      UNLINK_BEACON: 'CHAUFFEUR - DESASSOCIATION BEACON',
      UPDATE_INFOS: 'CHAUFFEUR - MODIFICATION INFOS',
      RESULTS: 'CHAUFFEUR - ONGLET BILAN',
      SESSIONS: 'CHAUFFEUR - ONGLET SESSIONS',
      CSV_EXPORT: 'CHAUFFEUR - CSV EXPORT',
      EXCEL_EXPORT: 'CHAUFFEUR - EXCEL EXPORT',
    },
    MAP: {
      ADD_PARCEL: 'MAP - AJOUT DE PARCELLE',
      EDIT_PARCEL: 'MAP - EDITION DE PARCELLE',
      MEASURE_DISTANCE: 'MAP - MESURER UNE DISTANCE',
      MEASURE_AREA: 'MAP - MESURER UNE SURFACE',
      IGN_TILES: 'MAP - FOND DE CARTE IGN',
      SATELLITE_TILES: 'MAP - FOND DE CARTE SATELLITE',
    },
    LEFT_PANE: {
      COLLAPSE_LEFT_PANE: 'GENERAL - CACHER LE MENU GAUCHE',
    },
    EQUIPMENT_SESSION: {
      SHARE: 'SESSION MAT - LIEN PARTAGE',
      SEND_BY_MAILS: 'SESSION MAT - ENVOI PAR MAIL',
      SHOW_SPEED_MAP: 'SESSION MAT - VITESSE',
      PARCEL_SESSIONS: 'SESSION MAT - ONGLET SESSIONS',
      OBSERVATIONS: 'SESSION MAT - ONGLET OBSERVATIONS',
      ADD_OBS: 'SESSION MAT - AJOUTER UNE OBSERVATION',
      EDIT_PARCELS: 'SESSION MAT - AJUSTER PARCELLES',
    },
    DASHBOARD: {
      REFRESH: 'TABLEAU DE BORD - ACTUALISATION DES DONNEES',
      IGNORE_PARCELS: 'TABLEAU DE BORD - IGNORER PARCELLES',
      ALLOCATE_PARCELS: 'TABLEAU DE BORD - ASSIGNER PARCELLES',
    },
    OBSERVATIONS: {
      ADD_LOCATION: 'OBSERVATIONS - AJOUTER UN LIEU',
      ADD_PHOTO: 'OBSERVATIONS - AJOUTER DES PHOTOS',
      ADDED: 'OBSERVATIONS - AJOUTEE',
      ARCHIVED: 'OBSERVATIONS - ARCHIVEE',
      DELETE: 'OBSERVATIONS - CLICK DELETE',
      DELETED: 'OBSERVATIONS - DELETED',
      EDIT: 'OBSERVATIONS - CLICK EDIT',
      EDITED: 'OBSERVATIONS - EDITED',
      EXPORT: 'OBSERVATIONS - EXPORT',
      FILTER: 'OBSERVATIONS - FILTER',
      LOCATION_ADDED: 'OBSERVATIONS - LOCATION ADDED',
      RESTORED: 'OBSERVATIONS - RESTORED',
      TOP_ADD: 'OBSERVATIONS - TOP ADD MODAL',
      TIMELINE_ADD: 'OBSERVATIONS - TIMELINE ADD MODAL',
    },
    TOOLTIPS: {
      EQUIPMENT: 'EQUIPMENT',
      PARCEL: 'PARCEL',
      OBSERVATION: 'OBSERVATION',
      WORKSITE: 'WORKSITE',
      DRIVER: 'CHAUFFEUR',
      TRACK: 'TRACE',
      DIAGNOSE: 'DIAGNOSTIQUER',
    },
    TAGS: {
      LINK: {
        FROM_LIST: 'TAGS - TAG AJOUTE DEPUIS UNE LISTE',
        FROM_DETAILS: 'TAGS - TAG AJOUTE DEPUIS UNE PAGE',
      },
      UNLINK: {
        FROM_LIST: 'TAGS - TAG RETIRE DEPUIS UNE LISTE',
        FROM_DETAILS: 'TAGS - TAG RETIRE DEPUIS UNE PAGE',
      },
      FILTER: {
        PARCEL: 'TAGS - FILTRE LISTE PARCELLES',
        EQUIPMENT: 'TAGS - FILTRE LISTE EQUIPEMENTS',
        DRIVER: 'TAGS - FILTRE LISTE CHAUFFEURS',
        DEVICE: 'TAGS - FILTRE LISTE KARNOTT',
        BEACON: 'TAGS - FILTRE LISTE KARNOTT OUTIL',
        DRIVER_BEACON: 'TAGS - FILTRE LISTE KARNOTT CHAUFFEUR',
        REPORTS: {
          PARCEL_TAG: (context) => `TAGS - ${context} - FILTRE TAG SUR PARCELLE`,
          EQUIPMENT_TAG: (context) => `TAGS - ${context} - FILTRE TAG SUR EQUIPEMENT`,
        },
      },
    },
    TASKS: {
      CLUSTER_EDIT_EMAIL: 'TASKS - CLUSTER EDIT EMAIL',
    },
    FAQ: {
      PARCEL: {
        IMPORT_PARCEL: 'FAQ - PARCEL - IMPORT',
      },
    },
  },
};

export const sendAnalyticsBtnClickEvent = (window) => {
  if (import.meta.env.DEV) {
    return () => {};
  }

  const karnottEvent = (event) => {
    karnottTrackingEvent({
      category: ANALYTICS.CATEGORY.BUTTON,
      action: ANALYTICS.ACTION.CLICK,
      message: event,
    });
  };
  const btnClickEvent = (event) => `${ANALYTICS.CATEGORY.BUTTON} - ${ANALYTICS.ACTION.CLICK} - ${event}`;
  const intercom = (event) => window.Intercom('trackEvent', btnClickEvent(event));

  const sentryEvent = (event) =>
    Sentry.getInstance().logEvent({
      category: Sentry.CATEGORIES.BUTTON_CLICK,
      level: Sentry.EVENT_LEVELS.Log,
      message: btnClickEvent(event),
    });
  const mixPanelEvent = (event) => mixpanel.track(btnClickEvent(event));
  return (event) => {
    intercom(event);
    sentryEvent(event);
    mixPanelEvent(event);
    karnottEvent(event);
  };
};

const sendTranslateEvent = (window) => {
  if (import.meta.env.DEV) {
    return () => {};
  }

  const karnottEvent = (lang) =>
    karnottTrackingEvent({
      category: ANALYTICS.CATEGORY.TRANSLATION,
      action: ANALYTICS.ACTION.TRANSLATE,
      message: lang,
    });
  const btnClickEvent = (event) => `${ANALYTICS.CATEGORY.TRANSLATION} - ${ANALYTICS.ACTION.TRANSLATE} - ${event}`;
  const intercomEvent = (lang) => window.Intercom('trackEvent', btnClickEvent(lang));

  const sentryEvent = (lang) =>
    Sentry.getInstance().logEvent({
      category: Sentry.CATEGORIES.TRANSLATION,
      level: Sentry.EVENT_LEVELS.Log,
      message: lang,
    });
  const mixPanelEvent = (lang) => mixpanel.track(((lang) => btnClickEvent(lang))(lang));
  return (lang) => {
    sentryEvent(lang);
    intercomEvent(lang);
    mixPanelEvent(lang);
    karnottEvent(lang);
  };
};

export const sendAnalyticsLoginEvent = () => {
  if (import.meta.env.DEV) {
    return () => {};
  }

  const karnottEvent = (event) =>
    karnottTrackingEvent({
      action: 'LOGIN',
      message: event,
    });

  return karnottEvent;
};

export const sendAnalyticsNavigationEvent = () => {
  if (import.meta.env.DEV) {
    return () => {};
  }

  const karnottEvent = (event) =>
    karnottTrackingEvent({
      action: 'NAVIGATION',
      message: event,
    });

  return karnottEvent;
};

export const sendAnalyticsLinkClickEvent = (window) => {
  if (import.meta.env.DEV) {
    return () => {};
  }

  const karnottEvent = (event) =>
    karnottTrackingEvent({
      category: ANALYTICS.CATEGORY.LINK,
      action: ANALYTICS.ACTION.CLICK,
      message: event,
    });
  const linkClickEvent = (event) => `${ANALYTICS.CATEGORY.LINK} - ${ANALYTICS.ACTION.CLICK} - ${event}`;
  const intercom = (event) => window.Intercom('trackEvent', linkClickEvent(event));
  const sentryEvent = (event) =>
    Sentry.getInstance().logEvent({
      category: Sentry.CATEGORIES.LINK_CLICK,
      level: Sentry.EVENT_LEVELS.Log,
      message: linkClickEvent(event),
    });
  const mixPanelEvent = (event) => mixpanel.track(linkClickEvent(event));
  return (event) => {
    intercom(event);
    sentryEvent(event);
    mixPanelEvent(event);
    karnottEvent(event);
  };
};

export const sendAnalyticsTooltipClickEvent = (window) => {
  if (import.meta.env.DEV) {
    return () => {};
  }

  const karnottEvent = (event) =>
    karnottTrackingEvent({
      category: ANALYTICS.CATEGORY.TOOLTIP,
      action: ANALYTICS.ACTION.CLICK,
      message: event,
    });
  const tooltipClickEvent = (event) => `${ANALYTICS.CATEGORY.TOOLTIP} - ${ANALYTICS.ACTION.CLICK} - ${event}`;
  const intercom = (event) => window.Intercom('trackEvent', tooltipClickEvent(event));
  const sentryEvent = (event) =>
    Sentry.getInstance().logEvent({
      category: Sentry.CATEGORIES.TOOLTIP_CLICK,
      level: Sentry.EVENT_LEVELS.Log,
      message: tooltipClickEvent(event),
    });
  const mixPanelEvent = (event) => mixpanel.track(tooltipClickEvent(event));
  return (event) => {
    intercom(event);
    sentryEvent(event);
    mixPanelEvent(event);
    karnottEvent(event);
  };
};

const trackGoogleTranslations = () => {
  if (import.meta.env.DEV) {
    return;
  }
  if (window.MutationObserver) {
    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const oldElementClass = mutation.oldValue;
        const currentElementClass = mutation.target.className;
        if (
          oldElementClass &&
          oldElementClass.indexOf('translated-') === -1 &&
          currentElementClass.indexOf('translated-') > -1
        ) {
          sendTranslateEvent(window)(
            mutation.target.lang || document.getElementsByTagName('html')[0].getAttribute('xml:lang'),
          );
        }
      });
    });

    const htmlNode = document.querySelector('html');
    mutationObserver.observe(htmlNode, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class'],
    });
  }
};

trackGoogleTranslations();
